import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { AppConstants } from '../../../utils/Constants'

function AboutModal(props) {

    const save = () => {
        props.setShowAboutModal(false)
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"About"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Genesis Provider Application<br />
                    Version {AppConstants.app_version} Released {AppConstants.app_release_date}<br />
                    Copyright  © 2024 Genesis Community Rehabilitation Inc.<br />
                    Powered by eFXs Technologies Inc.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={save}>{"close"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AboutModal
import axios from "axios"

function apiURL() {
    let url = ''
    switch(window.location.hostname ) {
        case 'app.mygenesislive.local' :
            url = 'http://app.mygenesislive.local/api/';
            break;
        case 'app.mygenesislive.com' :
            url = 'https://mygenesislive.com/api/';
            break;
        case 'app.uat.mygenesislive.com' :
            url = 'https://uat.mygenesislive.com/api/';
            break;
        default :
            url = 'http://localhost:3000/api/';
    }
    return url;
}

export default axios.create({
    // BW*****
    //baseURL: "http://localhost:3000/api/",
    //baseURL: window.location.protocol+'//' + ((window.location.hostname == 'localhost') ? 'localhost:3000' : window.location.hostname) + '/api/',
    //baseURL: window.location.protocol+'//' + window.location.hostname + '/api/',
    //baseURL: "http://192.168.2.111:3000/api/",
    //baseURL: "https://uat.mygenesislive.com/api/",
    //baseURL: "https://mygenesislive.com/api/",
    baseURL: apiURL(),
    headers: {
        'Content-Type': 'application/json'
    }
});

/*******************************************************
 * For newer nodejs you may need to use this command
 * before running npm start :
 * export NODE_OPTIONS=--openssl-legacy-provider
 * 
 * ,
        'Access-Control-Allow-Origin': 'http://localhost:3001',
        'Access-Control-Allow-Methods': 'POST, OPTIONS'
 *******************************************************/